import { useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import { TabContentModel } from '@model/tab.model';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { FeaturedItemModel } from '@model/featured-item-list.model';
import { useColors } from '@hooks/useColors';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { getFieldLink } from '@utils/functions/getFieldLink';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { HomepageTabPanelProps } from '@molecules/HomepageTabPanel/HomepageTabPanel.props';
import styles from './TabsParagraph.module.scss';

const Box = dynamic(() => import('@mui/material/Box'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const GridMask = dynamic(() => import('@atoms/GridMask/GridMask'));
const BgDotsVector = dynamic(() => import('@atoms/CustomVectors/BgDotsVector'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const TabList = dynamic(() => import('@molecules/TabList/TabList'));
const HomepageTabPanel = dynamic(() => import('@molecules/HomepageTabPanel/HomepageTabPanel'));
const IntroParagraph = dynamic(() => import('@organisms/IntroParagraph/IntroParagraph'));

// const TAB_HEIGHT = 730;
const TAB_HEIGHT = 'auto';

const TabsParagraph = ({ node, fieldParagraph }: FieldParagraphProps) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const colorVariant = fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light';
    const newData: TabContentModel = {
      title: fieldParagraph.field_title,
      overline: fieldParagraph.field_overline,
      verticalLinesDirection: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      colorVariant,
      items: fieldParagraph.field_items.map((item) => {
        const props = getHomepagePanelProps(item, t);

        return {
          title: item.field_overline,
          content: <HomepageTabPanel {...props} colorVariant={colorVariant} />,
        };
      }),
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
    };

    return newData;
  }, [
    fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class,
    fieldParagraph.behavior_settings?.style_options?.toc?.label,
    fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class,
    fieldParagraph.field_items,
    fieldParagraph.field_overline,
    fieldParagraph.field_title,
    t,
  ]);

  const boxRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();

  const { backgroundColor } = useColors(data?.colorVariant);

  const { isLarger } = useBreakpoint('lg');

  const verticalPadding = useMemo(() => {
    return isLarger ? 104 : 80;
  }, [isLarger]);

  if (!data) return null;

  return (
    <Box
      ref={boxRef}
      position="relative"
      bgcolor={backgroundColor}
      py={verticalPadding}
      anchor-data={transformTitleId(data.anchorData || '')}
    >
      <FadeIn>
        <GridMask direction={data.verticalLinesDirection} colorVariant={data.colorVariant} />

        <Box position="relative" zIndex={1} mb={{ xs: 40, lg: 56 }}>
          <IntroParagraph
            node={node}
            isBackgroundColorDisabled={true}
            removePadding={true}
            mockDataUrl="/data/tabs.json"
            fieldParagraph={fieldParagraph}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            // height: TAB_HEIGHT * data.items.length,
            height: TAB_HEIGHT,
          }}
        >
          <BgDotsVector sxOverride={{ position: 'absolute', right: 0, top: theme.spacing(-140) }} />

          <GridContainer ref={containerRef} className={styles.Container}>
            <Row>
              <Grid item lg={1} xs={0} />
              <Grid item lg={10} xs={12}>
                <TabList
                  paddingTop={verticalPadding}
                  // tabHeight={TAB_HEIGHT}
                  parentBoxRef={boxRef}
                  colorVariant={data.colorVariant}
                  parentContainerRef={containerRef}
                  tabs={data.items}
                />
              </Grid>
              <Grid item lg={1} xs={0} />
            </Row>
          </GridContainer>
        </Box>
      </FadeIn>
    </Box>
  );
};

export default TabsParagraph;

function getHomepagePanelProps(item, translate): Omit<HomepageTabPanelProps, 'colorVariant'> {
  // const latestProjects = translate('latestProjects');

  return {
    backgroundText: item.field_title,
    cta: getFieldLink(item.field_link),
    description: item.field_description?.value,
    featuredItems: item.field_view
      ? {
          // title: latestProjects,
          title: item.view?.id.split('--')[0],
          variant: item.field_view?.resourceIdObjMeta.drupal_internal__target_id === 'projects' ? 'project' : 'article',
          items: item.view.results
            ?.map(
              (itemV) =>
                ({
                  titleTab: item.field_title,
                  title: itemV.title,
                  imageUrl: absoluteUrl(itemV?.field_media?.field_media_image.uri.url),
                  cta: {
                    url: itemV.path.alias || '/',
                    isExternal: false,
                  },
                  iconName: 'article',
                } as FeaturedItemModel)
            )
            .slice(0, 3),
        }
      : null,
  };
}
